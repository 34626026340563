<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="1250"
        persistent
      >
        <template>
          <v-card>
            <v-toolbar color="primary" dark>Nuevo Débito</v-toolbar>
            <v-container>
              <v-progress-linear
                v-if="loading"
                :loading="loading"
                indeterminate
              ></v-progress-linear>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-row>
                  <v-col cols="12" md="6">
                    <company-field
                      v-model="companyId"
                      @value="(val) => (companyId = val)"
                    ></company-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <account-field
                      v-model="body.account_id"
                      :companyId="companyId"
                    ></account-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <reason-movement-field
                      v-model="reason"
                      kind="DEB"
                      :rules="onlyRequired"
                      :filterReason="true"
                    ></reason-movement-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <date-field
                      label="Fecha"
                      v-model="body.movement_date"
                      :error-messages="movementDateError"
                      @clear="movementDateError = []"
                      :rules="onlyRequired"
                    ></date-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-checkbox v-model="checkbox" label="Cheque"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-if="checkbox"
                      label="No.Cheque"
                      :rules="checkRules"
                      v-model="body.check_code"
                    ></v-text-field>

                    <v-text-field
                      v-else
                      label="No. Transacción"
                      :rules="textRules"
                      v-model="body.code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Valor"
                      v-model="body.deb"
                      :rules="valueRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <tt-field v-model="body.tt_id" :credeb="'DEB'"></tt-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Descripción"
                      counter="200"
                      v-model="body.description"
                      :rules="textRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" v-if="showDocumentField">
                    <v-text-field
                      :label="reason.document_required_name"
                      :rules="textRules"
                      v-model="body.document"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-switch
                  label="Agregar Detalle de Egreso"
                  v-model="thereAredetailReason"
                ></v-switch>
                <v-divider></v-divider>
                <v-row v-if="thereAredetailReason">
                  <v-col cols="12" md="4">
                    <reason-movement-field
                      v-model="detailReason.reason_id"
                      kind="DEB"
                      :filterReason="true"
                      :reset="reset"
                    ></reason-movement-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Monto"
                      v-model="detailReason.amount"
                      ref="amount"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn elevation="2" large color="info" @click="addReason()">
                      Agregar</v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-data-table
                  v-if="thereAredetailReason"
                  :headers="headers"
                  :items="body.accountmovementdetail"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    Q {{ item.amount }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon @click="removeReason(item, item.amount)" color="red darken-2"
                      >mdi-delete-outline</v-icon
                    >
                  </template>
                </v-data-table>
                <v-row v-if="thereAredetailReason">
                  <v-col cols="12" md="5" class="text-center">TOTAL</v-col>
                  <v-col cols="12" md="2" class="text-center"
                    ><b> Q {{ totalReason }}</b></v-col
                  >
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" @click="resetForm()">Cancelar</v-btn>
                  <v-btn
                    color="success"
                    @click="create()"
                    :disabled="!valid"
                    :loading="loading"
                    >Registrar</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";
export default {
  props: {
    dialog: { type: Boolean, required: true },
  },
  components: {
    CompanyField: () => import("@/components/fields/CompanyField"),
    AccountField: () => import("@/components/fields/AccountField"),
    ReasonMovementField: () => import("@/components/fields/ReasonMovementField"),
    DateField: () => import("@/components/fields/DateField"),
    TtField: () => import("@/components/fields/TTField"),
  },
  data: () => ({
    thereAredetailReason: false,
    reset: false,
    checkbox: true,
    valid: false,
    showDocumentField: false,
    loading: false,
    reason: {},
    companyId: 0,
    body: {
      tt_id: 0,
      account_id: 0,
      reason_id: undefined,
      code: "",
      check_code: "",
      description: "",
      movement_date: "",
      deb: "",
      document: " ",
      credeb: "deb",
      accountmovementdetail: [],
      reason_detail: false,
    },
    detailReason: {
      reason_id: undefined,
      amount: 0,
    },
    totalReason: 0,
    headers: [
      {
        text: "Tipo Ingreso",
        align: "start",
        value: "name",
      },
      {
        text: "Descripción",
        align: "start",
        value: "description",
      },
      {
        text: "Monto",
        value: "amount",
      },
      {
        text: "",
        value: "actions",
      },
    ],
    // form rules
    // textRules: [rules.required, rules.minLenght(5)],
    textRules: [rules.required, rules.minLength(1)],
    checkRules: [rules.required, rules.minLength(1)],
    // onlyRequired: [rules.required],
    onlyRequired: [rules.required],
    // valueRules: [rules.required, rules.minValue(0.1)],
    valueRules: [rules.required, rules.minValue(0.01)],

    // //Error messages
    movementDateError: [],
  }),
  methods: {
    create() {
      this.loading = true;
      if (this.body.document == null) {
        this.body.document = " ";
      }
      if (!this.checkbox) {
        this.body.check_code = null;
      } else {
        this.body.code = null;
      }
      console.log(JSON.stringify(this.body));
      requests.post("api/accounts/movements-in/", this.body).then((response) => {
        if (response.status == 201) {
          this.resetForm();
          this.$toasted.global.info({
            message: "Registro Creado",
          });
        } else if (response.status == 400) {
          if (response.data.non_field_errors) {
            this.$toasted.global.error({
              message: "Ya existe un operación registrada con este número de boleta",
            });
          }

          if (response.data.code) {
            this.$toasted.global.error({
              message: response.data.code,
            });
          }
          if (response.data.movement_date != undefined) {
            this.movementDateError = response.data.movement_date;
          }
          this.movementDateError = response.data.movement_date;

          if (response.data.detail) {
            this.$toasted.global.info({
              message: response.data.detail,
            });
          }
        }
        this.loading = false;
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.body = {
        tt_id: 0,
        account_id: 0,
        reason_id: 0,
        code: "",
        description: "",
        movement_date: "",
        deb: "",
        document: " ",
        credeb: "deb",
        reason_detail: false,
        accountmovementdetail: [],
      };
      this.thereAredetailReason = false;
      this.valid = false;
      this.showDocumentField = false;
      this.reason = {};
      this.totalReason = 0;
      this.$emit("close");
    },
    addReason() {
      if (this.detailReason.amount > 0 && this.detailReason.reason_id != undefined) {
        this.body.accountmovementdetail.push({
          reason_id: this.detailReason.reason_id.id,
          name: this.detailReason.reason_id.reason,
          description: this.detailReason.reason_id.description,
          amount: this.detailReason.amount,
        });
        this.totalReason = Number(
          Number(this.totalReason) + Number(this.detailReason.amount)
        ).toFixed(2);
        this.reset = this.reset ? false : true;
        this.$refs.amount.reset();
        this.detailReason = {
          reason_id: {},
          amount: 0,
        };
      }
    },
    removeReason(item) {
      let index = this.body.accountmovementdetail.indexOf(item);
      this.body.accountmovementdetail.splice(index, 1);
      this.totalReason = Number(Number(this.totalReason) - Number(item.amount)).toFixed(
        2
      );
    },
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
    },
    reason(newValue) {
      if (typeof newValue === "object") {
        if (Object.keys(newValue).length > 0) {
          this.body.reason_id = newValue.id;
          this.showDocumentField = newValue.document_required_name;
        }
      }
    },
    thereAredetailReason(val) {
      this.body.reason_detail = val;
      this.detailReason = {
        reason_id: undefined,
        amount: 0,
      };
      this.totalReason = 0;
      this.body.accountmovementdetail = [];
    },
  },
};
</script>
